<script setup>
const props = defineProps({
  color: {
    type: String,
    default: "#5D0201"
  }
})
</script>

<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9766 9H24.9566C26.7566 9 28.4966 9.54 29.9366 10.5L38.5166 16.32C38.9366 16.62 39.4166 16.8 39.9566 16.86" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M18.6562 9H24.4762" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M40.0156 16.8008L45.4756 17.6408C47.3356 17.9408 49.0156 18.7808 50.0356 20.2808C50.6956 21.1208 50.9356 22.0808 50.9356 22.9808V23.6408C50.9356 25.5608 49.3756 27.1208 47.4556 27.1208H46.7356" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M18.9531 27.0605H31.1331" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M18.9531 16.7402L40.0131 16.8002" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M43.7372 27.0605C43.7372 29.6405 41.6972 31.6205 39.1772 31.6205C36.6572 31.6205 34.6172 29.5805 34.6172 27.0605" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M43.7372 27.06C43.7372 24.48 41.6972 22.5 39.1772 22.5C36.6572 22.5 34.6172 24.54 34.6172 27.06" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M21.5391 9V16.74" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M24.5391 45.8399H39.1791C40.2591 45.8399 41.2791 45.4199 42.0591 44.6999L51.2391 37.0199C52.2591 36.0599 51.8991 34.3199 50.5791 33.7799C49.4391 33.3599 48.2991 33.6599 46.6791 34.7399L39.1191 39.7199" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.1816 40.3204H34.8016C35.8216 40.2004 36.5416 39.3604 36.6016 38.3404C36.6016 37.1404 35.7616 36.1804 34.5616 36.1204C30.4816 36.1204 27.6016 34.5604 24.4816 33.7804C18.9616 32.8804 16.2616 36.0004 15.6016 36.9004" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.88542 41.3099L11.6007 38.5946C12.1523 38.0431 13.0856 38.0431 13.6372 38.5946L20.8921 45.8495C21.4436 46.4011 21.4436 47.3344 20.8921 47.886L18.1768 50.6013C17.6253 51.1528 16.6919 51.1528 16.1403 50.6013L8.88542 43.3464C8.33388 42.7948 8.33388 41.8614 8.88542 41.3099Z" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>
