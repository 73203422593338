<script setup>
const props = defineProps({
  color: {
    type: String,
    default: "#5D0201"
  }
})
</script>

<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6009_127561)">
      <path d="M40.9782 55.5592H19.0907C17.5015 55.5592 16.2734 54.1145 16.2734 52.3808V7.37761C16.2734 5.64394 17.5015 4.19922 19.0907 4.19922H40.9782C42.5674 4.19922 43.7955 5.64394 43.7955 7.37761V52.3808C43.7955 54.1145 42.5674 55.5592 40.9782 55.5592Z" :stroke="props.color" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M16.2031 11.4219H43.7974" :stroke="props.color" stroke-width="2" stroke-linejoin="round"/>
      <path d="M16.2031 47.1797H43.7974" :stroke="props.color" stroke-width="2" stroke-linejoin="round"/>
      <path d="M27.4688 7.66602H32.5253" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M30.8665 51.2243C30.8665 51.6577 30.5053 52.0189 29.9996 52.0189C29.494 52.0189 29.1328 51.6577 29.1328 51.2243C29.1328 50.7909 29.494 50.4297 29.9996 50.4297C30.5053 50.4297 30.8665 50.7909 30.8665 51.2243Z" :stroke="props.color" stroke-width="2" stroke-linejoin="round"/>
      <path d="M33.1094 34.6113L38.816 40.8959" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M35.9922 31.4316L39.1706 35.0435" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M35.9265 31.2163C37.7324 28.0379 37.299 23.9204 34.6263 21.2477C31.9535 18.575 29.2085 18.3582 26.3191 19.1528L30.7977 23.6315V26.5932L28.2695 29.1215H25.3078L20.8291 24.6428C19.9623 27.46 20.6124 30.7107 22.924 32.95C25.5967 35.6227 29.7142 36.1284 32.8926 34.2502" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_6009_127561">
        <rect width="60" height="60" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
