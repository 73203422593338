<script setup>
const props = defineProps({
color: {
  type: String,
  default: "#5D0201"
}
})
</script>

<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6009_127536)">
      <path d="M5.49219 25.8638L19.6277 13.471C20.4022 12.8256 21.564 12.8256 22.274 13.6001C22.984 14.3101 23.0486 15.4719 22.4031 16.2465L9.36492 29.7365" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.2734 20.0547V27.8002C22.2734 28.8974 23.1125 29.7365 24.2098 29.7365C25.3071 29.7365 26.1462 28.8974 26.1462 27.8002V27.1547" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.26562 26.5098L9.36563 32.9643" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.7189 47.1644C21.036 47.1644 22.9144 45.286 22.9144 42.9689C22.9144 40.6518 21.036 38.7734 18.7189 38.7734C16.4018 38.7734 14.5234 40.6518 14.5234 42.9689C14.5234 45.286 16.4018 47.1644 18.7189 47.1644Z" :stroke="props.color" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      <path d="M11.9445 42H1.61719" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M25.502 41.9993H26.1475V37.4811L20.9838 32.9629H2.26562" :stroke="props.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M38.7969 22.3789C39.1841 22.6371 39.5714 22.7662 40.0232 22.8307L48.8014 23.7989C50.286 23.9925 51.706 24.5735 52.8678 25.6062L56.0951 28.3816" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M53.1953 25.7988L57.0035 29.0261" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M28.7296 25.2827L28.2778 24.8954C27.0515 23.8627 26.8578 21.9264 27.9551 20.7L28.3424 20.2482C28.8587 19.6673 29.5042 19.1509 30.4078 19.0218C31.8924 18.6345 33.4415 19.0218 34.7969 19.8609L38.7987 22.3136" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M38.8672 33.8672L46.8063 40.5799" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M38.7969 22.3145L52.4805 33.8681" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M36.6076 31.931C35.1876 33.5447 32.7349 33.7383 31.1212 32.3829C29.5076 31.0274 29.2494 28.5747 30.6694 26.8965" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M36.6023 31.931C38.0223 30.3173 37.8286 27.8646 36.1504 26.4446C34.4722 25.0246 32.0841 25.2182 30.6641 26.8964" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M50.8047 32.4466L55.1292 27.4766" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_6009_127536">
        <rect width="60" height="60" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
