<script setup>
const props = defineProps({
  color: {
    type: String,
    default: "#5D0201"
  }
})
</script>

<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6009_127600)">
      <path d="M8.75781 28.0195H14.1578C16.0778 28.0195 17.9978 28.6195 19.6178 29.6395L29.0378 35.9995C29.5178 36.2995 30.0578 36.5395 30.5978 36.5995" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M7.25781 28.0195H13.6178" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M30.6016 36.5996L36.5416 37.4996C38.5216 37.7996 40.3816 38.7596 41.5216 40.3796C42.1816 41.2796 42.5416 42.2996 42.5416 43.3196V43.9796C42.5416 46.0796 40.8616 47.7596 38.7616 47.7596H37.9816" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M7.55469 47.8203H20.9347" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M7.55469 36.4805L30.5947 36.6005" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M34.7413 47.8203C34.7413 50.5803 32.5212 52.8003 29.7612 52.8003C27.0012 52.8003 24.7812 50.5803 24.7812 47.8203" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M34.7413 47.8198C34.7413 45.0598 32.5212 42.8398 29.7612 42.8398C27.0012 42.8398 24.7812 45.0598 24.7812 47.8198" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M10.4375 28.0195V36.4795" :stroke="props.color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M37.7416 32.4602C36.8416 32.5802 35.9416 32.5802 35.1616 32.4602C31.7416 31.8602 28.9816 29.0402 28.4416 25.6202C28.3216 24.7802 28.3216 24.0002 28.4416 23.1602" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M37.8566 26.9991C37.4966 27.0591 37.0766 27.0591 36.7766 26.9991C35.9966 26.8791 35.3966 26.5191 34.8566 25.9791C34.3766 25.4391 34.0166 24.7791 33.8966 24.1191C33.8366 23.7591 33.8366 23.3991 33.8966 23.0391" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M37.4962 29.8204C37.0762 29.8804 36.6562 29.8804 36.2362 29.8204C34.8562 29.6404 33.6562 28.9804 32.7562 28.0204C31.8562 27.0604 31.1962 25.8004 31.0762 24.6004C31.0163 24.1804 31.0163 23.8204 31.0762 23.4004" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M42 15.5401L37.68 21.0601C37.44 21.3601 37.44 21.7201 37.68 21.9601L38.88 23.1601C39.12 23.4001 39.48 23.4001 39.78 23.1601L45.3 18.8401C45.6 18.6001 45.54 18.1801 45.3 17.9401L42.84 15.4801C42.66 15.3001 42.24 15.2401 42 15.5401Z" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M32.9969 7.37971L28.9769 11.3997C28.7369 11.6397 28.7369 12.0597 28.9769 12.2997L34.3169 17.6397C34.7969 18.1197 38.2769 18.5397 39.4769 18.7797L40.5569 17.3997C40.3769 16.1397 39.8969 13.2597 39.2969 12.6597L33.9569 7.31971C33.6569 7.13971 33.2369 7.13971 32.9969 7.37971Z" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M53.5175 27.8995L49.4975 31.9195C49.2575 32.1595 48.8375 32.1595 48.5975 31.9195L43.1975 26.6395C42.7175 26.1595 42.1175 22.7995 41.9375 21.5995L43.3775 20.5195C44.6375 20.6995 47.5775 21.1195 48.1775 21.7195L53.5175 27.0595C53.7575 27.2395 53.7575 27.6595 53.5175 27.8995Z" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_6009_127600">
        <rect width="60" height="60" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
